<template>
	<div class="page-main">
		<div class="login-header">
			<div class="container-inner">
				<div class="c-logo fl">
					<a href="https://bd.ruiyi126.com/" class="c-logo-img">
						<img src="../../assets/images/logo.png" alt="瑞蚁云表单">
					</a>
				</div>
				<div class="c-btngroup fr">
					<router-link to="/register"><el-button type="primary" size="small">注册</el-button></router-link>
					<a href="https://bd.ruiyi126.com/" class="b-btn b-blue-primary">返回首页</a>
				</div>
			</div>
		</div>
		<div class="login-main">
			<div class="login-inner">
				<div class="login-infor">
					<div class="change_btn" :class="{code_btn:loginType == 1}" @click="changeLoginType(this)"></div>
					<h2>瑞蚁云表单 · 后台管理登录</h2>
					<div class="login_code" v-if="loginType == 1">
						<div class="code_wrap">
							<div class="code_img" ref="qrCodeUrl"></div>
						</div>
						<div class="tips">
							<img src="../../assets/images/wechat.png">
							<p>微信扫一扫立即登录</p>
						</div>
					</div>
					<template v-else>
						<el-form class="login-form" ref="form" :model="form" :rules="rules" label-position="top">
							<el-form-item label="手机号/邮箱/用户名" prop="userName">
								<el-input v-model="form.userName" placeholder="请输入帐号"></el-input>
							</el-form-item>
							<el-form-item label="密码" prop="password">
								<el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
							</el-form-item>
							<el-form-item class="login-btns">
								<el-button type="primary" class="login-btn" @click="login('form')">登录</el-button>
							</el-form-item>
						</el-form>
						<p class="register"><router-link to="/register" class="font-gray">立即注册</router-link></p>
					</template>
				</div>
			</div>
		</div>
		<p class="login-footer">Copyright © 2019 jinrf.com All Rights Reserved. 北京金瑞帆科技有限公司 京ICP备05027158号</p>
		<!-- 拼图验证码 -->
		<el-dialog ref="dialog" title="请完成安全验证！" :close-on-click-modal='false' :visible.sync="flag" width='320px'>
			<slide-verify :l="42" :r="10" :w="280" :h="158" :imgs="filenames" slider-text="向右滑动填充拼图" @success="onSuccess" class="slide-box" ref="slideBlock"></slide-verify>
		</el-dialog>
	</div>
</template>
<script>
import QRCode from 'qrcodejs2';
import { get,post} from "/src/services/ajax_ucenter.js";
export default {
	components: {
	},
	data(){
		return {
			from:'',
			loginType:1,
			form:{
				userName:'',
				password:''
			},
			rules: {
				userName: [
					{ required:true,message:"用户名不能为空", trigger: 'blur' },
				],
				password: [
					{ required:true,message:"密码不能为空",  trigger: 'blur' }
				],
			},
			flag:false,
			filenames:[
				require("@/assets/verifyphotogallery/1.jpg"),
				require("@/assets/verifyphotogallery/2.jpg"),
				require("@/assets/verifyphotogallery/3.jpg"),
			]
		}
	},
	watch: {
	},
	mounted(){
		let filenames=[];
		for(var i=0;i<100;i++){
			filenames.push(require("@/assets/verifyphotogallery/"+i+".jpg"))
		}
		this.filenames = filenames;
		if(this.$route.query.from){
			this.from = this.$route.query.from;
		}
		if(this.loginType == 1){
			this.creatQrCode();
		}
	},
	methods: {
		changeLoginType(){
			const _this = this;
			if(_this.loginType == 1){
				_this.loginType = 0;
			}else{
				_this.loginType = 1;
				_this.$nextTick(()=>{
					_this.creatQrCode();
				})
				
			}
		},
		creatQrCode () {
			const url = window.location.origin + '/formadmin/getWxQrcode.jhtml';
			new QRCode(this.$refs.qrCodeUrl, {
				text: url,
				width: 210,
				height: 210,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.L
			})
		},
		// 获取表单分享设置
		login(loginForm){
			const _this = this;
			this.$refs[loginForm].validate((valid) => {
				if (valid) {
					// 拼图出现
					_this.flag = true;
					if(_this.$refs.slideBlock){
						_this.$refs.slideBlock.reset()
					}
				}
			});
			
		},
		// 拼图成功
		onSuccess (){
			const _this = this;
			_this.flag = false;
			post('/formadmin/login.jhtml',this.form).then(res => {
				if(res.code == 200){
					if(_this.from != ''){
						_this.getAuthToken();
					}else{
						_this.$router.push("/")
					}
					
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		getAuthToken(){
			const _this = this;
			get('/formadmin/getFormDesignAuthToken.jhtml',{}).then(res => {
				if(res.code == 200){
					let identityType = res.data.identityType;
					let authToken = res.data.authToken;
					post('/com/auth.api',{identityType,authToken}).then(res => {
						if(res.code == 200){
							sessionStorage.setItem('form_token',res.data.form_token)
							if(_this.from == 'bd'){
								window.location.href = 'http://bd.ruiyi126.com/dtgc/?token='+res.data.form_token
							}else if(_this.from == 'price'){
								window.location.href = 'http://bd.ruiyi126.com/gujia/?token='+res.data.form_token
							}else{
								_this.$router.push("/")
								// _this.getInfo()
							}
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
					// _this.getPage(formId,res.data.identityType,res.data.authToken,viewType)
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
	}
};
</script>
<style lang="less" scoped>
	.page-main {
		background: #f3f5f6;
		background: url(../../assets/images/login_bg.jpg) no-repeat center;
		display: flex;
		flex-direction: column;
		flex:1;
		.login-header{
			background: #fff;
			padding:18px 23px;
			text-align: center;
			border-bottom:1px solid #eee;
			.container-inner{
				width: 1200px;
				margin: 0 auto;
			}
			.c-logo-img{
				display: inline-block;
				vertical-align: middle;
			}
			.c-btngroup{
				display: flex;
				.b-btn{
					border:1px solid var(--theme-color);
					border-radius:4px;
					font-size: 14px;
					display: inline-block;
					text-align: center;
					width:78px;
					height:32px;
					line-height: 32px;
					margin-left:15px;
					color:var(--theme-color);background: #fff;
					&:hover{
						background: var(--theme-color);
						color:#fff;
					}
				}
			}
		}
		.login-footer{
			text-align: center;
			color:#999;
			padding-bottom: 10px;
		}
		.login-main{
			width:100%;
			position: relative;
			flex: 1;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.login-inner{
				margin:30px auto;
				width:1105px;
				height:520px;
				background:url(../../assets/images/login_img.jpg) #fff no-repeat left center;
				border-radius:18px;
				box-shadow: 0 0 11px 8px rgba(194,194,194,0.32);
				position: relative;
				.login-infor{
					padding:65px 70px 0;
					position: absolute;
					right:0;
					top:0;
					width: 48%;
					.change_btn{
						width: 38px;
						height: 34px;
						background:url(../../assets/images/login_code.png) no-repeat right top;
						position: absolute;
						top:21px;
						right: 21px;
						cursor: pointer;
						z-index: 99;
					}
					.code_btn{
						background-image: url(../../assets/images/login_account.png);
					}
					h2{
						font-size: 28px;
						color:#333;
						line-height: 1.5;
						text-align: center;
						padding-bottom: 46px;
					}
					.login-form{
						// position: absolute;
						// left:52%;
						// top:50%;
						// transform: translateY(-50%);
						// width: 430px;
						// margin:0 52px;
						::v-deep .el-form-item{
							margin-bottom:16px;
							.el-form-item__label{
								font-size: 16px;
								color:#666;
								line-height: 26px;
							}
							.el-form-item__content{
								.el-input__inner{
									height:46px;
									font-size: 14px;
									line-height: 46px;
								}
							}
						} 
						.login-btns{
							border:none;
							padding-top: 30px;
							.login-btn{
								width:100%;
								height:46px;
								line-height:46px;
								padding:0;
								font-size: 16px;
								box-sizing: border-box;
								&:hover{
									background:#1eb1e2;color:#fff;
								}
							}
						}
					}
					.register{
						text-align: right;
					}
					.login_code{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						.code_wrap{
							width: 230px;
							height: 230px;
							padding: 10px;
							border: 1px solid #e5e5e5;
							margin: 0 auto 30px;
							.code_img{width: 100%;height: 100%;}
						}
						.tips{
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							color: #888;
							img{margin: 0 10px 0 0;}
						}
					}
				}
			}
		}
		::v-deep .el-dialog__body {
			padding-top: 10px;
		}
		::v-deep .slide-verify-slider-mask,::v-deep .slide-verify-slider-mask-item{
			border-color: var(--theme-custom-color);
		}
		::v-deep .slide-verify-slider-mask-item:hover{
			background: var(--theme-custom-color);
		}
		::v-deep .container-active {
			.slide-verify-slider-mask,.slide-verify-slider-mask-item{
				border-color: var(--theme-custom-color);
			}
			.slide-verify-slider-mask-item:hover{
				background: var(--theme-custom-color);
				border-color: var(--theme-custom-color);
			}
		}
	}
</style>